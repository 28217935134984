<template>
  <CopyToMyAccount />
</template>

<script>
import CopyToMyAccount from "@/components/copy_to/CopyToMyAccount";

export default {
  name: "MyAccount",
  components: { CopyToMyAccount }
};
</script>

<style scoped></style>
