<template>
  <LoadingBar v-if="loading"></LoadingBar>
  <Page v-else title="My Account">
    <v-form ref="form" v-model="valid" lazy-validation>
      <CopyToAddressBlock @countryUpdated="countryUpdated" />
      <CopyToPhoneBlock :country_code="country_code" :fax_rule="faxRule()" />
      <CopyToReportingPreferences />
      <v-row class="pa-5">
        <v-spacer></v-spacer>
        <v-btn @click="save()" color="primary">
          Save
        </v-btn>
      </v-row>
    </v-form>
  </Page>
</template>
<script>
import LoadingBar from "@/components/loaders/LoadingBar";
import Page from "@/components/layout/Page";
import CopyToAddressBlock from "@/components/copy_to/CopyToAddressBlock";
import CopyToPhoneBlock from "@/components/copy_to/CopyToPhoneBlock";
import CopyToReportingPreferences from "@/components/copy_to/CopyToReportingPreferences";
import { mapActions, mapState } from "vuex";
import rules from "@/utils/rules";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CopyToMyProfile",
  components: {
    LoadingBar,
    Page,
    CopyToAddressBlock,
    CopyToPhoneBlock,
    CopyToReportingPreferences
  },
  data() {
    return {
      loading: true,
      valid: false,
      country_code: ""
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapFields("copyTo", ["communication_types_selected"])
  },
  methods: {
    ...mapActions(["notify"]),
    ...mapActions("baseData", [
      "getCountries",
      "getStates",
      "getCommunicationTypes"
    ]),
    ...mapActions("copyTo", ["getActiveCopyTo", "editActiveCopyTo"]),
    save() {
      if (!this.$refs.form.validate()) return false;
      this.loading = true;
      this.editActiveCopyTo()
        .then(
          () => (
            (this.loading = false),
            this.notify({
              message: `Successfully updated account information`
            })
          )
        )
        .catch(res => {
          console.log(res);
          this.notify({
            message: `There was an error updating account information`
          });
        });
    },
    countryUpdated(val) {
      this.country_code = val.country_code;
    },
    faxRule() {
      // If fax has been selected, return required rule
      return this.communication_types_selected.includes(3)
        ? [rules.required]
        : [];
    }
  },
  mounted() {
    Promise.all([
      this.getActiveCopyTo(this.user.copy_to),
      this.getCountries(),
      this.getStates(),
      this.getCommunicationTypes()
    ]).then(() => (this.loading = false));
  }
};
</script>

<style scoped></style>
