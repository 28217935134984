<template>
  <v-row align-content="center" justify="center">
    <v-col>
      <div>Receive Reports Via</div>
      <template v-for="communication_type in communication_types">
        <v-list-item :key="communication_type.id">
          <v-checkbox
            :label="communication_type.name"
            :value="communication_type.id"
            v-model="communication_types_selected"
          />
        </v-list-item>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CopyToReportingPreferences",
  computed: {
    ...mapGetters("baseData", ["communication_types"]),
    ...mapFields("copyTo", ["active_copy_to", "communication_types_selected"])
  }
};
</script>

<style scoped></style>
