<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        name="address_1"
        v-model="address_1"
        label="Address 1"
        :rules="rules.conditionalAlphanumeric"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        name="address_2"
        v-model="address_2"
        label="Address 2"
        :rules="rules.conditionalAlphanumeric"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        name="city"
        v-model="city"
        label="City"
        :rules="rules.conditionalAlphanumeric"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        :rules="rules.conditionalAlphanumeric"
        :items="countries"
        label="Country"
        no-data-text="No Countries Available"
        item-text="country_name"
        item-value="id"
        v-model="country_id"
        countryUpdated
      ></v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-text-field
        name="postal_code"
        v-model="postal_code"
        label="Postal Code"
        :rules="rules.conditionalAlphanumeric"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        :rules="rules.conditionalAlphanumeric"
        v-if="filterById('states', country_id, 'country_id').length"
        :items="filterById('states', country_id, 'country_id')"
        label="State/Province"
        no-data-text="No States/Provinces Available"
        item-text="title"
        item-value="id"
        v-model="state_id"
      ></v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  name: "CopyToAddressBlock",
  props: ["countryUpdated"],
  data() {
    return {
      loading: false,
      rules: {
        conditionalAlphanumeric: [
          v => {
            if (v && v.length != 0) {
              return /^[a-z\d\s]+$/i.test(v) || "Must be alphanumeric";
            }
            return true;
          }
        ],
        sourceDialog: false
      }
    };
  },
  computed: {
    ...mapGetters("baseData", ["countries", "states", "filterById"]),
    ...mapFields("copyTo", [
      "active_copy_to.address_1",
      "active_copy_to.address_2",
      "active_copy_to.city",
      "active_copy_to.state_id",
      "active_copy_to.country_id",
      "active_copy_to.postal_code"
    ])
  },
  watch: {
    country_id: function(val) {
      const countryInfo = this.filterById("countries", val, "id");
      if (countryInfo) {
        this.$emit("countryUpdated", countryInfo[0]);
      }
    }
  }
};
</script>

<style scoped></style>
