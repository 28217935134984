<template>
  <v-row>
    <v-col cols="6">
      <vue-tel-input-vuetify
        v-model="workPhone"
        label="Work Phone"
        v-bind="bindProps"
        ref="telInput0"
      ></vue-tel-input-vuetify>
    </v-col>
    <v-col cols="6">
      <vue-tel-input-vuetify
        v-model="homePhone"
        label="Home Phone"
        v-bind="bindProps"
        ref="telInput1"
      ></vue-tel-input-vuetify>
    </v-col>
    <v-col cols="6">
      <vue-tel-input-vuetify
        v-model="mobilePhone"
        label="Mobile Phone"
        v-bind="bindProps"
        ref="telInput2"
      ></vue-tel-input-vuetify>
    </v-col>
    <v-col cols="6">
      <vue-tel-input-vuetify
        v-model="faxNumber"
        label="Fax Number"
        v-bind="bindProps"
        ref="telInput3"
        :rules="conditionalRequire()"
      ></vue-tel-input-vuetify>
    </v-col>
  </v-row>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import "vuetify/dist/vuetify.min.css";
import { mapFields } from "vuex-map-fields";

export default {
  name: "CopyToPhoneBlock",
  components: { VueTelInputVuetify },
  props: ["country_code", "fax_rule"],
  data() {
    return {
      bindProps: {
        mode: "international",
        defaultCountry: this.setDefaultCountryVueTel(),
        disabledFetchingCountry: true
      }
    };
  },
  computed: {
    workPhone: {
      get() {
        return this.work_phone || "";
      },
      set(val) {
        this.work_phone = val;
      }
    },
    homePhone: {
      get() {
        return this.home_phone || "";
      },
      set(val) {
        this.home_phone = val;
      }
    },
    mobilePhone: {
      get() {
        return this.mobile_phone || "";
      },
      set(val) {
        this.mobile_phone = val;
      }
    },
    faxNumber: {
      get() {
        return this.fax || "";
      },
      set(val) {
        this.fax = val;
      }
    },
    ...mapFields("copyTo", [
      "active_copy_to.home_phone",
      "active_copy_to.work_phone",
      "active_copy_to.mobile_phone",
      "active_copy_to.fax"
    ])
  },
  methods: {
    setDefaultCountryVueTel() {
      if (this.country_code.length == 0) {
        return "US";
      } else {
        return this.country_code;
      }
    },
    conditionalRequire() {
      return this.fax_rule;
    }
  },
  watch: {
    country_code: function(val) {
      // Get country
      const country = this.$refs.telInput0.findCountry(val);
      this.$refs.telInput0.choose(country);
      this.$refs.telInput1.choose(country);
      this.$refs.telInput2.choose(country);
      this.$refs.telInput3.choose(country);
    }
  }
};
</script>
